import React, { useEffect, useState } from "react";
import "./assessment.scss";
import Stepper from "../stepper/Stepper";
import AssessMentPercentage from "../../widgets/assessmentPersentage/AssessMentPercentage";
import TextArea from "../common/TextArea";
import TextField from "../common/TextField";
import CheckOption from "../common/CheckOption";
import MultiTextField from "../common/MultiTextField";

import { ApiConfig } from "../../services/ApiConfig";
import PasswordResetModal from "../PasswordResetModal";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import RadioButton from "../common/RadioButton";
import DropDown from "../common/DropDown";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import RadioButtonQuiz from "../common/RadioButtonQuiz";
import TextAreaQuiz from "../common/TextAreaQuiz";
// import DropDownQuiz from "../common/DropDownQuiz";
import CheckOptionQuiz from "../common/CheckOptionQuiz";
import DropDownSurvey from "../common/survey/DropDownSurvey";

export default function SurveyAssessment({
  pageTitle,
  renderData,
  activeStep,
  handlePreviousStep,
  handleNextStep,
  initialAnswers,
  handleInitialAnswers,
  moduleId,
  cohortId,
  pageLimit,
  getQuizAssessmentData,
  questionId,
}) {
  const [stepper, setStepper] = useState([]);
  const [answerDetails, setAnswersDetails] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const location = useLocation();
  console.log(pageLimit);
  console.log(cohortId);
  // console.log(renderData, "ghghgh");

  const path = location?.pathname?.split("/");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const formDetails = renderData;
  console.log(formDetails, "formDetails===>>>");

  console.log(renderData);

  const handleFormData = () => {
    // console.log(data);
    // const arrData = data;
    // const stepperData = arrData
    //   .filter((item) => {
    //     if (item.is_active) {
    //       return item;
    //     }
    //   })
    //   .map((step, i) => {
    //     return { step: i + 1, title: step?.assesment_category_name };
    //   });
    setStepper(pageLimit);
  };
  useEffect(() => {
    console.log(formDetails);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateForm = async (data) => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.addCohortSurveyAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          user_id: sessionStorage.getItem("userId"),
          cohort_id: cohortId,
          module_id: moduleId,
          question_id: parseInt(sessionStorage.getItem("version")),
          answers: [data],
        },
      });
      if (res.data.statusCode === 200) {
        handleInitialAnswers();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitForm = async (type) => {
    console.log(formDetails);
    const updatedAnswers = formDetails.map((item) => {
      if (answerDetails[item.id]) {
        return {
          ...answerDetails[item.id],
          question_id: item.id,
          cohort_id: cohortId,
          user_id: sessionStorage.getItem("userId"),
        };
      } else {
        console.log(cohortId);
        return {
          answer: "",
          question_id: item.id,
          cohort_id: cohortId,
          user_id: sessionStorage.getItem("userId"),
        };
      }
    });

    setIsDataLoading(true);

    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.addCohortSurveyAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: { answers: updatedAnswers },
      });

      if (res.data.statusCode === 200) {
        setIsDataLoading(false);

        if (type === "submit") {
          setIsModalOpen(true);
        } else if (type === "next") {
        } else {
          toast.success("Answers saved successfully!");
        }
      }
    } catch (error) {
      setIsDataLoading(false);
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  const handleRenderForm = (form, i) => {
    console.log(form?.answer_type);

    switch (form?.answer_type) {
      case "textArea":
        return (
          <TextArea
            serialNumber={i}
            placeHolder="Type here..."
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            key={i}
            // handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
          />
        );
      // case "checkBox":
      //   return (
      //     <CheckOptionQuiz
      //       formField={form}
      //       setAnswersDetails={setAnswersDetails}
      //       answersDetails={answerDetails}
      //       // handleUpdateForm={handleUpdateForm}
      //       handleSubmitForm={handleSubmitForm}
      //       key={i}
      //     />
      //   );
      case "radio":
        return (
          <RadioButton
            serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            // handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "dropDown":
        return (
          <DropDown
            serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            // handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "textBar":
        if (form?.answer?.length > 0) {
          return (
            <MultiTextField
              serialNumber={i}
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              key={i}
            />
          );
        } else {
          return (
            <TextField
              serialNumber={i}
              placeHolder="Type here..."
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              // handleUpdateForm={handleUpdateForm}
              // handleSubmitForm={handleSubmitForm}
              key={i}
            />
          );
        }
      default:
        break;
    }
  };

  useEffect(() => {
    handleFormData();
  }, []);

  return (
    <div
      style={{
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
      }}
    >
      <div className="discoveryContainer">
        {/* <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
          Quiz
        </h2> */}

        {isDataLoading ? (
          <Box className="spinner">
            <CircularProgress />
          </Box>
        ) : (
          <div className="stepperWrapper" style={{ marginTop: "2rem" }}>
            <Box
              sx={{
                width: "100%",
                "@media screen and (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              <div className="header"></div>

              {formDetails?.length > 0 &&
                formDetails
                  .filter((item) => {
                    if (item.is_active) {
                      return item;
                    }
                  })
                  .map((form, i) => handleRenderForm(form, i))}
              <div className="btnContainer">
                <div className="btnWrapper">
                  {/* {activeStep !== 1 && (
                    <button
                      className="previousBtn"
                      onClick={handlePreviousStep}
                    >
                      Previous
                    </button>
                  )}
                  {activeStep !== stepper ? (
                    <button
                      className="nextBtn"
                      onClick={() => {
                        handleSubmitForm();
                        handleNextStep();
                      }}
                      disabled={!Object.values(answerDetails)?.length > 0}
                    >
                      Next
                    </button>
                  ) : ( */}
                  <button
                    className="nextBtn"
                    onClick={() => {
                      // setIsModalOpen(true);
                      handleSubmitForm("submit");
                    }}
                  >
                    Submit
                  </button>
                  {/* )} */}
                </div>
              </div>
            </Box>
          </div>
        )}
      </div>
      <PasswordResetModal
        type="surveyAssessment"
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
}
