import React, { useEffect, useState } from "react";
import "./assessment.scss";
import Stepper from "../stepper/Stepper";
import AssessMentPercentage from "../../widgets/assessmentPersentage/AssessMentPercentage";
import TextArea from "../common/TextArea";
import TextField from "../common/TextField";
import CheckOption from "../common/CheckOption";
import MultiTextField from "../common/MultiTextField";
import { ApiConfig } from "../../services/ApiConfig";
import PasswordResetModal from "../PasswordResetModal";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import DropDown from "../common/DropDown";
import RadioButton from "../common/RadioButton";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default function Assessments({
  pageTitle,
  renderData,
  activeStep,
  handlePreviousStep,
  handleNextStep,
  initialAnswers,
  handleInitialAnswers,
}) {
  const [stepper, setStepper] = useState([]);
  const [answerDetails, setAnswersDetails] = useState([]);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFillModalOpen, setIsFillModalOpen] = useState(false);
  const formDetails = renderData[activeStep - 1]?.fundingAssessmentQuestions;
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleFormData = (data) => {
    const arrData = data?.assesmentCategoryMasters;
    const stepperData = data.map((step, i) => {
      return { step: i + 1, title: step?.funding_assessment_category_name };
    });
    setStepper(stepperData);
  };

  useEffect(() => {
    configureAnswerDetail();
  }, [initialAnswers]);

  const configureAnswerDetail = () => {
    const version = parseInt(sessionStorage.getItem("version"));
    if (initialAnswers !== "Assesment not found.") {
      const ans = initialAnswers?.map((data) => {
        return {
          [data?.question_id]: {
            answer: data?.answer,
            answer_id: data?.id,
            // assesment_id: data?.assesment_id,
            question_id: data?.question_id,
            // assesment_category_id: data?.assesment_category_id,
            version_control: version,
          },
        };
      });
      const mergedAnswers = Object.assign({}, ...ans);
      setAnswersDetails(mergedAnswers);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsFillModalOpen(false);
  };

  const handleUpdateForm = async (data) => {
    // try {
    //   const res = await customAxios({
    //     method: "POST",
    //     url: ApiConfig.updateUserAssesmentAnswers,
    //     headers: {
    //       token: sessionStorage.getItem("userToken"),
    //     },
    //     data: {
    //       business_owner_id: sessionStorage.getItem("userId"),
    //       assesment_id: sessionStorage.getItem("assessmentId"),
    //       version_control: parseInt(sessionStorage.getItem("version")),
    //       answer: [data],
    //     },
    //   });
    //   if (res.data.statusCode === 200) {
    //     handleInitialAnswers();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleSubmitForm = async (type) => {
    const updatedAnsers = formDetails.map((item, i) => {
      // if (item?.answer_type === "checkBox") {
      //   if (typeof answerDetails[item.id].answer === "string") {
      //     return {
      //       answer: answerDetails[item.id].answer,
      //       question_id: item?.id,
      //       assesment_category_id: item.assesment_category_,
      //       assesment_id: item?.assesment_master_id,
      //     };
      //   } else {
      //     answerDetails[item.id].answer =
      //       answerDetails[item.id].answer.join(",");
      //   }
      // }
      if (answerDetails[item.id]) {
        return answerDetails[item.id];
      } else {
        return {
          answer: "",
          question_id: item?.id,
          assesment_category_id: item.assesment_category_,
          assesment_id: item?.assesment_master_id,
        };
      }
    });

    // updatedAnsers?.forEach((e) => {
    //   if ("0663be86-5a6a-4f8d-b778-d9550ee6626e" == e?.question_id) {
    //     sessionStorage.setItem("fundingType", e?.answer);
    //   }
    //   if ("c9b2bf7a-9845-40e0-849c-bffcbe34a724" == e?.question_id) {
    //     sessionStorage.setItem("fundingAmount", e?.answer);
    //   }
    // });
    setIsDataLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userFundingAssessmentAnswers,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          answer: updatedAnsers,
          user_id: sessionStorage.getItem("userId"),
        },
      });
      if (res.data.statusCode === 200) {
        handleInitialAnswers();
        if (type === "submit") {
          setIsModalOpen(true);
        } else if (type === "next") {
        } else {
          // toast.success("Answers saved successfully!");
        }
        setIsDataLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsDataLoading(false);
    }
  };

  const handleRenderForm = (form, i) => {
    switch (form?.answer_type) {
      case "textArea":
        return (
          <TextArea
            serialNumber={i}
            placeHolder="Type here..."
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            key={i}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
          />
        );
      case "radio":
        return (
          <RadioButton
            formField={form}
            serialNumber={i}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "dropDown":
        return (
          <DropDown
            serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "checkBox":
        return (
          <CheckOption
            serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "textBar":
        if (form?.answer.length > 0) {
          return (
            <MultiTextField
              serialNumber={i}
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              key={i}
            />
          );
        } else {
          return (
            <TextField
              serialNumber={i}
              placeHolder="Type here..."
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              handleUpdateForm={handleUpdateForm}
              handleSubmitForm={handleSubmitForm}
              key={i}
            />
          );
        }
      default:
        break;
    }
  };

  useEffect(() => {
    handleFormData(renderData);
    setIsFillModalOpen(true);
  }, []);

  const handleNewVersion = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.checkActiveVersionControl,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          assesment_id: sessionStorage.getItem("assessmentId"),
        },
      });
      if (res.data?.result) {
        const currentVersion = sessionStorage.getItem("version");
        sessionStorage.setItem("version", currentVersion - -1);
        createNewVersion();
      } else {
        toast.error(res.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createNewVersion = async () => {
    const answerList = Object.values(answerDetails);
    const updatedAnsers = answerList.map((answer) => {
      return {
        ...answer,
        version_control: parseInt(sessionStorage.getItem("version")),
      };
    });
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userAssesmentAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: { answer: updatedAnsers },
      });
      if (res.data.statusCode === 200) {
        handleInitialAnswers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}>
      <div className="discoveryContainer">
        <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
          {pageTitle}
        </h2>

        {/* <div style={{ margin: "2.5rem 0 3.5rem 0" }}>
          <AssessMentPercentage type="funder" />
        </div> */}

        {isDataLoading ? (
          // <Box className="spinner">
          <Grid
            container
            item
            xs={12}
            sx={{
              minHeight: "500px",
            }}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <CircularProgress
              sx={{
                color: "#e16204",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              size={"70px"}
            />
            <Typography mt={6} variant="subtitle2">
              Loading Questionnaire
            </Typography>
          </Grid>
        ) : (
          <Grid container>
            {" "}
            <Grid
              xs={12}
              md={6}
              item
              className="scrollBarCustomColor"
              component={OverlayScrollbarsComponent}
              sx={{
                height: "80vh",
                overflowY: "scroll",
                marginY: "20px",
                width: "95%",
                // scrollbarWidth: "none", // For Firefox
                // "&::-webkit-scrollbar": {
                //   display: "none", // For Safari and Chrome
                // },
              }}
            >
              {/* <Stepper data={stepper} activeStep={activeStep} /> */}

              <div className="header">
                <h3 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
                  {stepper[activeStep - 1]?.title}
                </h3>
                {/* <button onClick={() => handleNewVersion()}>
                Create new version
              </button> */}
              </div>
              <div style={{ width: "90%" }}>
                {" "}
                {formDetails?.length > 0 &&
                  formDetails
                    .filter((item) => {
                      if (item.is_active) {
                        return item;
                      }
                    })
                    .map((form, i) => handleRenderForm(form, i))}
              </div>

              <div
                // style={{ display: "flex", justifyContent: "center" }}
                className="btnContainer"
              >
                <div className="btnWrapper">
                  {/* <button
                  className="saveBtn"
                  onClick={handleSubmitForm}
                  disabled={!Object.values(answerDetails).length > 0}
                >
                  Save
                </button> */}
                  {activeStep !== 1 && (
                    <button
                      className="previousBtn"
                      onClick={handlePreviousStep}
                    >
                      Previous
                    </button>
                  )}
                  {activeStep !== stepper.length ? (
                    <button
                      className="nextBtn"
                      onClick={() => {
                        handleSubmitForm();
                        handleNextStep();
                      }}
                      // disabled={!Object.values(answerDetails).length > 0}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="nextBtn"
                      onClick={() => {
                        // setIsModalOpen(true);
                        handleSubmitForm("submit");
                        navigate("fundersList", {
                          state: { type: "result" },
                        });
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </Grid>
            <Grid sx={{ display: { xs: "none", md: "block" } }} md={1}></Grid>
            <Grid
              md={5}
              xs={12}
              mt={7}
              item
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box component="img" src="/images/fundingmain.png" />
            </Grid>
          </Grid>
        )}
      </div>
      <PasswordResetModal
        type="assessment"
        open={isModalOpen}
        onClose={handleCloseModal}
      />
      <PasswordResetModal
        type="fillassessment"
        open={isFillModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
}
